<template>
  <div class="step_container">
    <h1 class="step_title">ENTITY DETAIL 公司信息</h1>
    <!-- 个人信息 -->
    <div class="step_question_container border_bot_red">
      <h2 class="step_question_title">
        ENTITY DETAIL 公司信息
      </h2>
      <a-form-model
        layout="horizontal"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
      <div class="mutiple_form_layout">
        <a-form-model-item
          :style="width45percent"
          required
          label='Entity Name 单位/公司名称'
        >
        <a-input
        v-decorator="[
          'password',
          {
            rules: [
              {
                required: true,
                message: 'Please input !',
              }
            ],
          },
        ]"
        @change="(e) => {
          handleInput(e, 'company_name');
        }"
        :value="company_name"
        >
        </a-input>
        </a-form-model-item>
        <a-form-model-item
          :style="width45percent"
          required
          label='Phone Number 公司电话'
        >
        <a-input
        @change="(e) => {
          handleInput(e, 'company_phone_num');
        }"
        :value="company_phone_num"
        >
        </a-input>
        </a-form-model-item>
      </div>
      <div class="mutiple_form_layout">
        <a-form-model-item
          :style="width45percent"
          required
          label='Registration No. 注册号码'
        >
        <a-input
        @change="(e) => {
          handleInput(e, 'company_register_num');
        }"
        :value="company_register_num"
        >
        </a-input>
        </a-form-model-item>
        <a-form-model-item
          :style="width45percent"
          required
          label='Tax Number 公司税号'
        >
        <a-input
        @change="(e) => {
          handleInput(e, 'company_tax_num');
        }"
        :value="company_tax_num"
        >
        </a-input>
        </a-form-model-item>
      </div>
      <div class="mutiple_form_layout">
        <a-form-model-item
          :style="width100percent"
          required
          label='What industry is your organisation in? 您的公司所属哪个行业？'
        >
        <a-input
        @change="(e) => {
          handleInput(e, 'company_industry_field');
        }"
        :value="company_industry_field"
        >
        </a-input>
        </a-form-model-item>
      </div>
      <div class="mutiple_form_layout">
        <a-form-model-item
          :style="width100percent"
          required
          label='What does your organisation do in the above industry? 您的公司在上述行业中做什么？'
        >
        <a-input
        @change="(e) => {
          handleInput(e, 'company_field_spec');
        }"
        :value="company_field_spec"
        >
        </a-input>
        </a-form-model-item>
      </div>
      <div>
        <div class="step_sub_title">Is the Entity a tax resident of another country (other than New Zealand)? 您的公司是否拥有其他国家（新西兰除外）的税务信息？
        </div>
        <ul class="step_ul_style">
              <li>
                If yes, please list all your other countries of tax residency below.
              </li>
              <li>
                若选择是请列出您公司其他国家的税务信息
              </li>
            </ul>
          <div class="step_checkbox_layout">
            <a-radio-group
            class="modify_antd_radio"
              @change="handleRadio"
              :value="entity_has_other_country_tax"
              name="entity_has_other_country_tax"
            >
              <a-radio :value="true"> Yes 是 </a-radio>
              <a-radio :value="false"> No 否 </a-radio>
            </a-radio-group>
          </div>
          <div
              class="step_checkbox_layout"
              v-if="
                entity_has_other_country_tax == true
              "
            >
              <!-- 是否有其他国家的 税务信息  -->
              <a-tabs
                v-model="activeKey"
                type="editable-card"
                @edit="onEdit"
                :hideAdd="entity_panes.length >= 3"
              >
                <a-tab-pane
                  v-for="pane in entity_panes"
                  :key="pane.key"
                  :tab="pane.title"
                  :closable="pane.closable"
                >
                  <div class="mutiple_form_layout">
                    <a-form-model-item
                      :style="width45percent"
                      required
                      label="Country of Tax Residence 税务国家"
                    >
                      <a-select
                        @change="
                          (value) => {
                            handleOtherTaxCountry(value, pane.key);
                          }
                        "
                        :value="pane.content.other_tax_contry"
                      >
                        <a-select-opt-group label="Popular Countries">
                        <a-select-option v-for='item of popularCountryList' v-bind:key="item.code" :value="item.code">
                          {{item.name}}
                        </a-select-option>
                      </a-select-opt-group>
                      <a-select-opt-group label="All Countries">
                        <a-select-option v-for='item of allCountryList' v-bind:key="item.code" :value="item.code">
                          {{item.name}}
                        </a-select-option>
                      </a-select-opt-group>
                      </a-select>
                    </a-form-model-item>
                    <a-form-model-item
                      :style="width45percent"
                      label="Tax Number 税号"
                    >
                      <a-input
                        @change="
                          (e) => {
                            handleOtherTaxNum(e, pane.key);
                          }
                        "
                        :value="pane.content.other_tax_num"
                      />
                    </a-form-model-item>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
      </div>
    </a-form-model>
    </div>
    <!-- 地址信息 -->
    <div class="step_question_container border_bot_red">
      <h2 class="step_question_title">ADDRESS AND CONTACT DETAILS 公司地址与联系方式</h2>
      <div>
        <a-form-model
        layout="horizontal"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        >
        <a-form-model-item
          :style="width100percent"
          required
          label='Business Address(No Po BOX) 公司地址'
        >
        <a-input
        @change="(e) => {
          handleInput(e, 'company_po_box');
        }"
        :value="company_po_box"
        >
        </a-input>
        </a-form-model-item>
      <div class="mutiple_form_layout">
        <a-form-model-item
          :style="width30percent"
          required
          label='City 城市'
        >
        <a-input
        @change="(e) => {
          handleInput(e, 'company_city');
        }"
        :value="company_city"
        >
        </a-input>
        </a-form-model-item>
        <a-form-model-item
            :style="width30percent"
            required
            label="Country 国家"
          >
            <a-select
              @change="
                (value) => {
                  handleSelect(value, 'company_country');
                }
              "
              :value="company_country"
            >
            <a-select-opt-group label="Popular Countries">
              <a-select-option v-for='item of popularCountryList' v-bind:key="item.code" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select-opt-group>
            <a-select-opt-group label="All Countries">
              <a-select-option v-for='item of allCountryList' v-bind:key="item.code" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select-opt-group>
            </a-select>
          </a-form-model-item>
        <a-form-model-item
          :style="width30percent"
          required
          label='Postcode 邮政编码'
        >
        <a-input
        @change="(e) => {
          handleInput(e, 'company_post_code');
        }"
        :value="company_post_code"
        >
        </a-input>
        </a-form-model-item>
      </div>
      <div class="step_checkbox_layout">
            <div class="step_sub_title">
              Please upload business address verification 请上传公司的地址认证
            </div>
            <a-radio-group
            class="modify_antd_radio"
            name= 'company_addressProveType'
            :value="company_addressProveType" @change="handleRadio">
              <a-radio :style="radioStyle" :value="1">
                Bank Statements and Bank Correspondence 银行对账单和银行通信
              </a-radio>
              <a-radio :style="radioStyle" :value="2">
                Utility Bill 各种账单
              </a-radio>
              <a-radio :style="radioStyle" :value="3">
                Government Agency Correspondence (Council Rates, etc.)
                政府机构通信</a-radio
              >
              <a-radio :style="radioStyle" :value="4">
                Electoral Commission Correspondence 选举委员会函件</a-radio
              >
              <a-radio :style="radioStyle" :value="5">
                Tenancy Agreement 租赁协议</a-radio
              >
              <a-radio :style="radioStyle" :value="6">
                Vehicle Registration 车辆登记</a-radio
              >
            </a-radio-group>
            <div class="step_checkbox_layout">
              <div v-if="company_addressProveType == 1">
                <ul class="step_ul_style">
                  <li>
                    This must be a statement from a bank/IRD that has been
                    posted to the client and is dated within the last 3 months.
                    这必须是银行/
                    IRD的记录，该记录信件必须是最近三个月内通过信件发送给注册人的。
                  </li>
                  <li>
                    Bank correspondence must confirm a business relationship
                    exists between the bank and the client.
                    银行通信必须是能确认银行与注册人之间存在业务关系的
                  </li>
                  <li>
                    Printed information from online account or email
                    correspondence is not acceptable unless it is JP/lawyer
                    certified. 除非获得JP
                    /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
              <a-upload
                action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                list-type="picture"
                name="userfile"
                @change='(e)=>{handleFileList(e,"company_bank_stament_address_file_list")}'
                :file-list="company_bank_stament_address_file_list"
              >
                <a-button> <a-icon type="upload" /> upload </a-button>
              </a-upload>
              <div class="step_notice_text marginT_10">
                The file's fomat supports JPG,PNG,JPEG,PDF or any video types
                and the size is not exceed 20MB
              </div>
            </div>
              </div>
              <div v-if="company_addressProveType == 2">
                <ul class="step_ul_style">
                  <li>
                    This must be a statement for utility service at a fixed
                    location that has been posted to the client and is dated
                    within the last 3 months.
                    这必须最近三个月内通过邮寄发送去注册人的账单。
                  </li>
                  <li>
                    Fixed services include electricity, water, gas, home
                    internet/landlines and Sky.
                    服务账单包括电力，水，煤气，家庭互联网/固定电话和电视。
                  </li>
                  <li>
                    Fixed services include electricity, water, gas, home
                    internet/landlines and Sky.
                    服务账单包括电力，水，煤气，家庭互联网/固定电话和电视。
                  </li>
                  <li>
                    Printed information from online account or email
                    correspondence is not acceptable unless it is JP/lawyer
                    certified. . 除非获得JP
                    /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
              <a-upload
                action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                list-type="picture"
                name="userfile"
                @change='(e)=>{handleFileList(e,"company_utility_address_file_list")}'
                :file-list="company_utility_address_file_list"
              >
                <a-button> <a-icon type="upload" /> upload </a-button>
              </a-upload>
              <div class="step_notice_text marginT_10">
                The file's fomat supports JPG,PNG,JPEG,PDF or any video types
                and the size is not exceed 20MB
              </div>
            </div>
              </div>
              <div v-if="company_addressProveType == 3">
                <ul class="step_ul_style">
                  <li>
                    This must be a statement, letter or invoice that has been
                    posted to the client from a government agency and is dated
                    within the last 3 months.
                    这必须最近三个月内来自政府机构通过邮寄发送去注册人的声明/信件/发票。
                  </li>
                  <li>
                    This includes confirmation of bond lodgements with the
                    Department of Building and Housing.
                    这可以是来自于建筑和住房部押金投管确认信
                  </li>
                  <li>
                    This includes confirmation of bond lodgements with the
                    Department of Building and Housing.
                    这可以是来自于建筑和住房部押金投管确认信
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
              <a-upload
                action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                list-type="picture"
                name="userfile"
                @change='(e)=>{handleFileList(e,"company_government_correspondence_address_file_list")}'
                :file-list="company_government_correspondence_address_file_list"
              >
                <a-button> <a-icon type="upload" /> upload </a-button>
              </a-upload>
              <div class="step_notice_text marginT_10">
                The file's fomat supports JPG,PNG,JPEG,PDF or any video types
                and the size is not exceed 20MB
              </div>
            </div>
              </div>
              <div v-if="company_addressProveType == 4">
                <ul class="step_ul_style">
                  <li>
                    This must be a letter that has been posted to the client and
                    is dated within the last 3 months.
                    这必须是三个月内通过邮寄发送至注册人的信件
                  </li>
                  <li>
                    unless it is JP/lawyer certified. . 除非获得JP
                    /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
              <a-upload
                action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                list-type="picture"
                name="userfile"
                @change='(e)=>{handleFileList(e,"cpmpany_commission_correspondence_1_address_file_list")}'
                :file-list="cpmpany_commission_correspondence_1_address_file_list"
              >
                <a-button> <a-icon type="upload" /> upload </a-button>
              </a-upload>
              <div class="step_notice_text marginT_10">
                The file's fomat supports JPG,PNG,JPEG,PDF or any video types
                and the size is not exceed 20MB
              </div>
            </div>
              </div>
              <div v-if="company_addressProveType == 5">
                <ul class="step_ul_style">
                  <li>
                    This must be a signed tenancy agreement dated within the
                    last 3 months. 这必须是过去3个月内签署的租赁协议
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
              <a-upload
                action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                list-type="picture"
                name="userfile"
                @change='(e)=>{handleFileList(e,"company_tenancy_agreement_file_list")}'
                :file-list="company_tenancy_agreement_file_list"
              >
                <a-button> <a-icon type="upload" /> upload </a-button>
              </a-upload>
              <div class="step_notice_text marginT_10">
                The file's fomat supports JPG,PNG,JPEG,PDF or any video types
                and the size is not exceed 20MB
              </div>
            </div>
              </div>
              <div v-if="company_addressProveType == 6">
                <ul class="step_ul_style">
                  <li>
                    This must be a current vehicle registration that has been
                    posted to the client and is dated within the last 3 months.
                    这必须是三个月内通过邮寄发送给注册人的车辆登记信件
                  </li>
                  <li>
                    Printed information from online account or email
                    correspondence is not acceptable unless it is JP/lawyer
                    certified. . 除非获得JP
                    /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                  </li>
                </ul>
                <div class="step_upload_box marginT_10">
              <a-upload
                action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                list-type="picture"
                name="userfile"
                @change='(e)=>{handleFileList(e,"company_vehicle_register_address_file_list")}'
                :file-list="company_vehicle_register_address_file_list"
              >
                <a-button> <a-icon type="upload" /> upload </a-button>
              </a-upload>
              <div class="step_notice_text marginT_10">
                The file's fomat supports JPG,PNG,JPEG,PDF or any video types
                and the size is not exceed 20MB
              </div>
            </div>
              </div>
            </div>
          </div>
        </a-form-model>
      </div>
    </div>
    <!-- 工作情况 -->
    <div class="step_question_container border_bot_green">
      <h2 class="step_question_title">
        Company Extract 公司文件
      </h2>
      <div class="step_upload_box marginT_10">
          <div class="step_sub_title">Please upload latest ASIC Company Extract 请上传最新的ASIC公司摘要</div>
              <a-upload
                action="https://fx.iemoney.co.nz/api/submit-cdd-file"
                list-type="picture"
                name="userfile"
                @change='(e)=>{handleFileList(e,"company_vehicle_register_address_file_list")}'
                :file-list="company_vehicle_register_address_file_list"
              >
                <a-button> <a-icon type="upload" /> upload </a-button>
              </a-upload>
              <div class="step_notice_text marginT_10">
                The file's fomat supports JPG,PNG,JPEG,PDF or any video types
                and the size is not exceed 20MB
              </div>
            </div>
    </div>
    <div class="step_question_container border_bot_green">
      <h2 class="step_question_title">
        SOURCE OF FUNDS/WEALTH AND PURPOSE OF TRADE 资金来源和交易目的
      </h2>
      <div class="">
      <a-form-model layout='horizontal' :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item required label="Soure of Funds/Wealth 资金来源">
          <a-textarea
      placeholder="Personal savings in bank in AU/NZ; sale of another property in AU/CN; inheritance; health products trading revenue; cosmetics importing and exporting revenue."
      :value='company_source_of_funds_text'
      @change='(e)=>{
        handleInput(e,"company_source_of_funds_text")
      }'
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
        </a-form-model-item>
        <a-form-model-item required label="Purpose of Transaction 换汇目的">
          <a-textarea
      placeholder="Repatriate overseas salary back to AU/NZ; purchasing property in AU/NZ; paying for overseas purchases,mainly cosmetics; paying tuition and living cost in AU/NZ. 将海外工资汇回澳大利亚/新西兰; 在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品; 在澳大利亚/新西兰支付学费和生活费。"
      :value='company_purpose_of_transaction_text'
      @change='(e)=>{
        handleInput(e,"company_purpose_of_transaction_text")
      }'
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
        </a-form-model-item>
        <a-form-model-item required label="List of countries normally dealing/trading with 请列出您产生交易/贸易的国家或地区">
          <a-textarea
      placeholder=""
      :value='company_transaction_country_list'
      @change='(e)=>{
        handleInput(e,"company_transaction_country_list")
      }'
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
        </a-form-model-item>
      </a-form-model>
    </div>
    </div>
    <div class="step_question_container border_bot_green">
      <h2 class="step_question_title">
        EMPLOYMENT/BUSINESS DETAILS (Optional) 工作情况/商业详情(可选填)
      </h2>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  created () {
  },
  methods: {
    handleFileList ({ fileList }, content) {
      const _fileList = fileList.map(item => ({
        ...item,
        url: item.response,
        originFileObj: null
      }))
      this.ChangeObjectAction({ content, value: _fileList })
    },
    handleOtherTaxCountry (val, param) {
      const index = this.entity_panes.findIndex(item => item.key === param)
      this.entity_panes[index].content.other_tax_contry = val
      this.ChangeObjectAction({ content: 'entity_panes', value: this.entity_panes })
    },
    handleOtherTaxNum (e, param) {
      const val = e.target.value
      const index = this.entity_panes.findIndex(item => item.key === param)
      this.entity_panes[index].content.other_tax_num = val
      this.ChangeObjectAction({ content: 'entity_panes', value: this.entity_panes })
    },
    handleCheck (e) {
      const { checked, name } = e.target
      this.ChangeFormAction({ content: name, value: checked })
    },
    onEdit (targetKey, action) {
      this[action](targetKey)
    },
    add () {
      const _panes = this.entity_panes
      _panes.push({ title: 'Tax Number 税务信息 # ' + (this.getLastPanesKey + 2), content: { other_tax_contry: null, other_tax_num: null }, key: this.getLastPanesKey + 1 })
      this.activeKey = this.getLastPanesKey
      this.ChangeObjectAction({ content: 'entity_panes', value: _panes })
    },
    remove (targetKey) {
      let activeKey = this.activeKey
      let _panes = {}
      let lastIndex
      this.entity_panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1
        }
      })
      _panes = this.entity_panes.filter(pane => pane.key !== targetKey)
      if (lastIndex >= 0) {
        activeKey = this.entity_panes[lastIndex].key
      } else {
        activeKey = this.entity_panes[0].key
      }
      this.activeKey = activeKey
      this.ChangeObjectAction({ content: 'entity_panes', value: _panes })
    },
    handleRadioHasOtherTax (e) {
      const { value } = e.target
      this.has_other_country_tax = value
    },
    handleInput (e, content) {
      const { value } = e.target
      console.log(content)
      this.ChangeFormAction({ content, value })
    },
    handleRadio (e) {
      const { name, value } = e.target
      this.ChangeFormAction({ content: name, value })
    },
    handleSelect (value, content) {
      this.ChangeFormAction({ content, value })
    },
    handleDatePick (value, content) {
      this.ChangeFormAction({ content, value })
    },
    ...mapActions('entityStepOne', ['ChangeFormAction', 'ChangeObjectAction'])
  },
  data () {
    return {
      activeKey: 0,
      // panes: [
      //   { title: 'Tax Number 税务信息 # 1', content: { other_tax_contry: '', other_tax_num: '' }, key: 0, closable: false }
      // ],
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      width30percent: {
        width: '30%'
      },
      width60percent: {
        width: '60%'
      },
      width45percent: {
        width: '45%'
      },
      width100percent: {
        width: '100%'
      },
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },
  computed: {
    getLastPanesKey () {
      return this.entity_panes[this.entity_panes.length - 1].key
    },
    ...mapState('entityStepOne', ['company_name', 'company_phone_num', 'company_register_num', 'company_tax_num', 'company_industry_field', 'company_field_spec', 'entity_has_other_country_tax', 'entity_panes', 'company_po_box', 'company_city', 'company_country', 'company_post_code', 'company_addressProveType', 'company_bank_stament_address_file_list', 'company_utility_address_file_list', 'company_government_correspondence_address_file_list', 'cpmpany_commission_correspondence_1_address_file_list', 'company_tenancy_agreement_file_list', 'company_vehicle_register_address_file_list', 'company_source_of_funds_text', 'company_purpose_of_transaction_text', 'company_transaction_country_list']),
    ...mapGetters('countrySelection', ['popularCountryList', 'allCountryList'])
  }
}
</script>

<style scoped>
.step_upload_box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}
.mutiple_form_layout {
  display: flex;
  justify-content: space-between;
}
.step_title {
  font-size: 22px;
  text-align: center;
  padding: 30px 0;
  font-weight: 300;
}
.step_container {
  background: rgb(248, 248, 248);
}
.step_notice_text {
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
}
.step_checkbox_layout {
  margin-top: 20px;
}
.step_question_container {
  max-width: 700px;
  margin: 0 auto 20px;
  background: #fff;
  padding: 30px 30px;
  border-radius: 2px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.ant-form-item-label .ant-form-item-required {
  font-size: 16px;
  color: red;
}
.step_sub_title {
  margin: 20px 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.step_ul_style {
  list-style-type: inside;
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 300;
}
.step_ul_style li {
  line-height: 24px;
}
.step_question_title {
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
}
.marginT_10 {
  margin-top: 10px;
}
.border_bot_red {
  border-bottom: 2px solid #f32e59;
}
.border_bot_green {
  border-bottom: 2px solid #2baf6f;
}
.modify_antd_radio >>> .ant-radio-inner{
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-inner::after{
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
.ant-form >>> label{
  font-size: 15px;
}

</style>
